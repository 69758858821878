import React from "react";
import { Icon, themes } from "@2ndmarket/components";
import { formatBRL } from "@2ndmarket/components/src/helper/formatters/fiatFormat";

import { Container, Text, Variation, BoxIcon } from "./styles";

interface ICrypto {
	crypto: any;
	key?: number;
}

const QuotationBlock: React.FC<ICrypto> = ({ crypto }) => {
	return (
		<Container>
			<div className="content">
				<div className="top-crypto">
					<BoxIcon crypto={crypto.name}>
						<Icon
							size={20}
							name={
								crypto.name === "Bkaicoin"
									? "2nd"
									: crypto.name.toLowerCase()
							}
							color={themes.bankai.colors.white}
						/>
					</BoxIcon>
					<Text>
						{crypto.abbreviation.toUpperCase()}
						<small>{crypto.name}</small>
					</Text>
				</div>
				<div className="bottom-crypto">
					<Text>R$ {formatBRL(crypto.buy * 100)}</Text>
					<Variation
						className={crypto.variation < 0 ? "down" : "up"}
					>
						<img
							src={
								crypto.variation < 0
									? "/icons/down-icon.svg"
									: "/icons/up-icon.svg"
							}
							alt=""
						/>
						{crypto.variation < 0
							? `${Number(crypto.variation)
									.toFixed(2)
									.replace(".", ",")}%`
							: `+${Number(crypto.variation)
									.toFixed(2)
									.replace(".", ",")}%`}
					</Variation>
				</div>
			</div>
		</Container>
	);
};

export default QuotationBlock;
