import Link from "next/link";
import { Icon } from "@2ndmarket/components";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import ProductCnpjs from "@2ndmarket/components/src/helper/ProductCnpjs";
import { BankaiSocials } from "@2ndmarket/components/src/helper/ProductSocialNetworks";

import {
	Container,
	Box,
	FooterContent,
	SocialMediaContainer,
	TextsContainer,
} from "./styles";

const Footer: React.FC = () => {
	const currentYear = new Date().getFullYear();

	return (
		<Container>
			<FooterContent>
				<SocialMediaContainer>
					<Link href="/">
						<a rel="noreferer">
							<img
								alt=""
								width={145}
								height={22}
								src="/bankai-logo.svg"
							/>
						</a>
					</Link>
					<div>
						<Link href={BankaiSocials.FACEBOOK}>
							<a target="_blank" rel="noreferer">
								<img
									alt=""
									width={18}
									height={18}
									src="/icons/facebook-icon.svg"
								/>
							</a>
						</Link>
						<Link href={BankaiSocials.LINKEDIN}>
							<a target="_blank" rel="noreferer">
								<img
									alt=""
									width={18}
									height={18}
									src="/icons/linkedin-icon.svg"
								/>
							</a>
						</Link>
						<Link href={BankaiSocials.INSTAGRAM}>
							<a target="_blank" rel="noreferer">
								<img
									alt=""
									width={18}
									height={18}
									src="/icons/instagram-icon.svg"
								/>
							</a>
						</Link>
					</div>
				</SocialMediaContainer>
				<Box>
					<p>Nosso ecossistema:</p>
					<Link href={ProductUrls.SECOND_MARKET}>
						<a target="_blank" rel="noreferrer">
							2ND Market{" "}
							<Icon
								name="external-link"
								color="#B4B9C7"
								size={12}
							/>
						</a>
					</Link>
					<Link href={ProductUrls.BRAEX}>
						<a rel="noreferrer" target="_blank">
							Bra.ex{" "}
							<Icon
								name="external-link"
								color="#B4B9C7"
								size={12}
							/>
						</a>
					</Link>
					<Link href={ProductUrls.CUSTON}>
						<a target="_blank" rel="noreferrer">
							Cust.on{" "}
							<Icon
								name="external-link"
								color="#B4B9C7"
								size={12}
							/>
						</a>
					</Link>
					<Link href={ProductUrls.TOKENAIM}>
						<a target="_blank" rel="noreferrer">
							Token.aim{" "}
							<Icon
								name="external-link"
								color="#B4B9C7"
								size={12}
							/>
						</a>
					</Link>
				</Box>
				<Box className="box-decorator">
					<div className="decorators">
						<img
							alt=""
							width={9}
							height={9}
							src="/four-squares.svg"
							className="decoration four"
						/>
						<img
							alt=""
							width={150}
							height={150}
							src="/logo-footer.svg"
							className="decoration logo"
						/>
						<img
							alt=""
							width={3}
							height={3}
							src="/one-square.svg"
							className="decoration one"
						/>
					</div>
				</Box>
			</FooterContent>
			<TextsContainer>
				<p>
					<a
						rel="noreferrer"
						target="_blank"
						href={ProductUrls.PRIVACY_POLICY}
					>
						Política de Privacidade
					</a>{" "}
					<a
						rel="noreferrer"
						target="_blank"
						href={ProductUrls.SUPPORT}
					>
						Preciso de Ajuda
					</a>
					<br />© {currentYear}{" "}
					<a
						rel="noreferrer"
						target="_blank"
						href={ProductUrls.SECOND_MARKET}
						className="market"
					>
						2ND Market
					</a>{" "}
					- Todos direitos reservados <br /> CNPJ{" "}
					{ProductCnpjs.BANKAI}
				</p>
				<img
					src="/2nd-footer.svg"
					alt="Logo 2nd Market"
					width={143}
					height={20}
				/>
			</TextsContainer>
		</Container>
	);
};

export default Footer;
