enum ProductUrls {
  AUTH_LOGIN = "https://auth.2ndmarket.com.br/",
  AUTH_REGISTER = "https://auth.2ndmarket.com.br/cadastro",
  SECOND_MARKET = "https://2ndmarket.com.br/",
  BLOG = "https://blog.2ndmarket.com.br/",
  BANKAI = "https://bank-ai.io/",
  BANKAI_ICO = "https://lp.bank-ai.io/",
  BANKAI_WHITEPAPER = "https://bank-ai.io/whitepaper-pt.pdf",
  BRAEX = "https://braexmarket.com.br/",
  CUSTON = "https://cust-on.io/",
  PRIVACY_POLICY = "https://2ndmarket.com.br/politica-de-privacidade/",
  SUPPORT = "https://suporte.2ndmarket.com.br/",
  TOKENAIM = "https://tokenaim.com.br/",
  TERMS_AND_CONDITIONS = "https://2ndmarket.com.br/termos-de-uso/",
  ZEKAI = "https://zekai.foundation/",
}

export default ProductUrls;
