import React from "react";

import { Container, Text } from "./styles";
import {
	Paragraph,
	Cartola,
	Title,
	SubTitle,
} from "../../components/Text";

interface BlockProps {
	title?: any;
	cartola?: string;
	children?: any;
	paragraph?: string;
	additional?: any;
	marginX?: number;
	reverse?: boolean;
	mobileReverse?: boolean;
	maxWidth?: number;
	mtMobile?: number;
	subTitle?: string;
	gap?: number;
}

const BlockPattern: React.FC<BlockProps> = ({
	children,
	additional,
	cartola,
	paragraph,
	title,
	marginX,
	reverse,
	mobileReverse,
	maxWidth,
	mtMobile,
	subTitle,
	gap,
}) => {
	return (
		<Container
			marginX={marginX}
			reverse={reverse}
			maxWidth={maxWidth}
			mobileReverse={mobileReverse}
			mtMobile={mtMobile}
			gap={gap}
		>
			<Text>
				<Cartola>{cartola}</Cartola>
				<Title>{title}</Title>
				{subTitle && <SubTitle>{subTitle}</SubTitle>}
				{paragraph && <Paragraph>{paragraph}</Paragraph>}
				{children}
			</Text>
			{additional}
		</Container>
	);
};

export default BlockPattern;
