import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vw;
  height: 115px;
  border-radius: 2px;
  position: relative;
  margin-top: 12px;
  background: ${theme("colors.background")};
  border: 1px solid ${theme("colors.light.gray.4")};
  border-radius: 2px;

  @media only screen and (max-width: 1200px) {
    width: 118px;
  }
`;
