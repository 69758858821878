import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
	crypto?: string;
}

export const Container = styled.li`
	width: 16vw;
	display: flex;

	padding: 15px;
	border: 1px solid ${theme("colors.light.gray.4")};
	box-shadow: 0px 4px 10px rgba(26, 36, 137, 0.05);

	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
  background-color: ${theme("colors.white")};;

	.content {
		width: 100%;
		> .top-crypto {
			gap: 10px;
			width: 100%;
			display: flex;
			flex-direction: row;
		}

		> .bottom-crypto {
			width: 100%;
			padding-top: 15px;

			display: flex;
			justify-content: space-between;
		}
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		min-width: 230px;
		display: flex;

		.content {
			> .top-crypto {
				flex-direction: row;
			}
		}
	}
`;

export const Text = styled.article`
	display: flex;
	flex-direction: column;

	small {
		font-size: 12px;
		font-weight: 500;
		line-height: 15px;
	}
`;

export const Variation = styled.p`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;

	&.up {
		font-weight: 500;
		color: ${theme("colors.success.main")};
	}

	&.down {
		font-weight: 500;
		color: ${theme("colors.error.main")};
	}

	img {
		margin-right: 5px;
	}
`;

export const BoxIcon = styled.div<Props>`
	width: 46px;
	height: 46px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.crypto &&
		theme(`colors.crypto.${props.crypto.toLowerCase()}`)};
`;
