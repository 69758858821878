import React from "react";

import { Container } from "./styles";

interface CardUtilitiesProps {
  img: string;
}

const CardUtilities: React.FC<CardUtilitiesProps> = ({ img }) => {
  return (
    <Container>
      <img src={img} alt="" />
    </Container>
  );
};

export default CardUtilities;
