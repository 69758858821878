import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	flex-direction: column;
	padding: 80px 4vw;
	display: flex;
	gap: 25px;

	img {
		width: fit-content;
	}

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		flex-direction: column;
		padding: 20px 4vw 10px 4vw;
	}
`;

export const BoxLinks = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	i {
		font-size: 12px;
		color: ${theme("colors.light.gray.3")};
	}

	> a {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${theme("colors.light.gray.2")};

		:hover {
			text-decoration: underline;
		}
	}
`;

export const FooterContent = styled.div`
	margin-top: 16px;
	display: flex;
	gap: 10vw;

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		flex-direction: column;
		gap: 0;
	}
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;

	a > img {
		margin: 0;
	}

	div {
		display: flex;
		gap: 40px;
	}

	@media only screen and (max-width: 1025px) {
		gap: 28px;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;

		a {
			margin: 0;
		}

		div {
			gap: 25px;
		}
	}
`;

export const Box = styled.div`
	gap: 9px;
	display: flex;
	margin-left: 210px;
	flex-direction: column;

	p {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 16px;
		color: ${theme("colors.light.gray.1")};
	}

	a {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		color: ${theme("colors.light.gray.1")};

		&.market {
			color: ${theme("colors.primary.main")};
		}

		:hover {
			color: ${theme("colors.primary.main")};
		}
	}

	&.box-decorator {
		margin-left: 150px;

		.decorators {
			gap: 100px;
			display: flex;
			flex-direction: row;

			> .four {
				margin-top: 139px;
			}

			> .logo {
				margin-top: 59px;
			}
		}
	}

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		margin: 40px 0 0 0;

		&.box-decorator {
			display: none;
			margin: 0;
		}

		p {
			display: none;
		}
	}
`;

export const TextsContainer = styled.div`
	p,
	a {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: ${theme("colors.light.gray.1")};
	}

	p {
		margin-bottom: 40px;
	}

	a {
		text-decoration: underline;

		&.market {
			color: ${theme("colors.primary.main")};
		}

		:hover {
			color: ${theme("colors.primary.main")};
		}
	}
`;
