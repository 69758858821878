import { Cartola } from "../Text";
import { Container, Text } from "./styles";
import dayjs from "dayjs";

interface BlogProps {
	date: string;
	text: string;
	category: number;
	img: string;
	link: string;
}

const BlogArticle: React.FC<BlogProps> = ({
	date,
	text,
	category,
	img,
	link,
}) => {
	const removeTagText = text.replace(/(<([^>]+)>)/gi, "");

	return (
		<Container target="_blank" href={link}>
			<img src={img} alt="" />
			<Text>
				<Cartola>{category}</Cartola>
				<h3>{removeTagText}</h3>
				<span>
					{dayjs(date).format("DD MMM YYYY").toUpperCase()}
				</span>
			</Text>
		</Container>
	);
};

export default BlogArticle;
