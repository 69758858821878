import { useState, useEffect, useCallback } from "react";

import {
	TextFieldFiat,
	CustomSelect,
	TextField,
	Icon,
	themes,
	IconName,
} from "@2ndmarket/components";

import {
	Container,
	BoxInput,
	BoxIcon,
	BoxCrypto,
	ContentTotal,
	Footer,
	ContentIcon,
	Text,
	WidgetCrypto,
	InfosConvert,
} from "./styles";

import { satoshiFormat } from "@2ndmarket/components/src/helper/formatters/satoshiFormat";
import { toBitcoin } from "@2ndmarket/components/src/helper/formatters/toBitcoin";

import {
	parseBRL,
	formatBRL,
} from "@2ndmarket/components/src/helper/formatters/fiatFormat";

import Button from "../Button";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

interface CryptoConvertProps {
	crypto?: "bitcoin" | "cardano";
}

const CryptoConvert: React.FC<CryptoConvertProps> = ({
	crypto,
}) => {
	const [value, setValue] = useState("");
	const [cryptos, setCryptos] = useState([]);
	const [cryptosOptions, setCryptosOptions] = useState([]);

	const [cryptoName, setCryptoName] =
		useState<IconName>("bitcoin");

	const [cryptoNameDefault, setCryptoNameDefault] =
		useState("bitcoin");

	const [convertedValue, setConvertedValue] = useState("");
	const [cryptoConvert, setCryptoConvert] = useState("btc");

	const findCryptoInfos = useCallback(() => {
		const found = cryptos.find(
			(element) => element.name.toLowerCase() === crypto
		);

		return {
			abv: found?.abbreviation.toUpperCase(),
			variation: found?.variation,
			buy: found?.buy,
		};
	}, [crypto, cryptos]);

	useEffect(() => {
		fetch("/api/cryptos")
			.then((response) => response.json())
			.then((responseData) => {
				setCryptos(responseData);
			})
			.catch((error) => console.warn(error));
	}, []);

	useEffect(() => {
		if (!crypto) return;

		setCryptoConvert(findCryptoInfos().abv?.toLowerCase());
	}, [crypto, setCryptoConvert, findCryptoInfos]);

	useEffect(() => {
		const found = cryptos.find(
			(element) => element.abbreviation === cryptoConvert
		);

		if (!found) return;

		const buyBRL = parseBRL(value);
		const crypto = satoshiFormat(found.buy);
		const convert = ((buyBRL / crypto) * 1000000).toFixed(8);

		setConvertedValue(convert.toString());
	}, [cryptoConvert, cryptos, value]);

	useEffect(() => {
		let arrayTemp = [];
		cryptos.forEach((element) => {
			arrayTemp.push({
				label: element.name,
				value: element.abbreviation,
			});
		});
		setCryptosOptions(arrayTemp);
	}, [cryptos]);

	const changeCryptoConvert = (value) => {
		const bkaiName = "bkaicoin";
		const bkaiNameIcon = "bkai-coin";

		setCryptoConvert(value);
		const found = cryptos.find(
			(element) => element.abbreviation === value
		);

		const cryptoName = found.name.toLowerCase();
		setCryptoNameDefault(cryptoName);

		if (cryptoName == bkaiName) {
			setCryptoName(bkaiNameIcon);
		} else {
			setCryptoName(cryptoName);
		}
	};

	return (
		<Container>
			{crypto && (
				<WidgetCrypto>
					<BoxIcon
						color={themes.bankai.colors.crypto[crypto]}
						position="inherit"
						width={42}
					>
						<Icon
							name={crypto}
							size={20}
							color={themes.bankai.colors.white}
						/>
					</BoxIcon>
					<InfosConvert>
						<h1>
							1 {findCryptoInfos().abv} ={" "}
							{formatBRL(findCryptoInfos().buy * 100)} BRL
						</h1>
						<p
							className={
								findCryptoInfos().variation < 0 ? "down" : "up"
							}
						>
							<img
								src={
									findCryptoInfos().variation < 0
										? "/icons/down-icon.svg"
										: "/icons/up-icon.svg"
								}
								alt=""
							/>
							{findCryptoInfos().variation < 0
								? `${Number(findCryptoInfos().variation)
										.toFixed(2)
										.replace(".", ",")}%`
								: `+${Number(findCryptoInfos().variation)
										.toFixed(2)
										.replace(".", ",")}%`}
						</p>
					</InfosConvert>
				</WidgetCrypto>
			)}
			{crypto ? (
				<h2>Adicione um valor:</h2>
			) : (
				<h2>Adicione um valor e selecione a criptomoeda:</h2>
			)}
			<div className="input">
				<TextFieldFiat
					name="crypto"
					onChange={setValue}
					placeholder="Quantidade"
					value={value}
				/>
				<span>BRL</span>
			</div>
			{crypto ? (
				<>
					<ContentTotal>
						<h2>Total</h2>
						<BoxCrypto>
							<BoxInput>
								<TextField
									name="crypto"
									onChange={null}
									readOnly
									placeholder=""
									value={
										isNaN(Number(convertedValue))
											? ""
											: Number(convertedValue).toFixed(8)
									}
								/>
								<span className="cryptoConvert">
									{findCryptoInfos().abv}
								</span>
							</BoxInput>
							<Button
								variant="tertiary"
								href={`https://app.bank-ai.io/cripto/btc`}
							>
								Negocie agora
							</Button>
						</BoxCrypto>
					</ContentTotal>
					<Footer>
						<ContentIcon>
							<Icon name="change" size={20} />
						</ContentIcon>
						<Text>
							<h3>Bank.ai com a melhor taxa do mercado!</h3>
							<p>
								Utilize BKAI para comprar suas critptos com até
								25% de desconto na corretagem.
							</p>
							<a href={ProductUrls.AUTH_REGISTER}>
								Experimente agora!
							</a>
						</Text>
					</Footer>
				</>
			) : (
				<BoxInput>
					<div className="crypto">
						<BoxIcon
							color={
								themes.bankai.colors.crypto[cryptoNameDefault]
							}
						>
							<Icon
								name={cryptoName}
								size={15}
								color={themes.bankai.colors.white}
							/>
						</BoxIcon>
						<CustomSelect
							onChange={changeCryptoConvert}
							options={cryptosOptions}
							placeholder=""
							value={cryptoConvert}
						/>
					</div>
					<TextField
						name="crypto"
						onChange={null}
						readOnly
						placeholder=""
						value={
							isNaN(Number(convertedValue))
								? ""
								: Number(convertedValue).toFixed(8)
						}
					/>
					<span className="cryptoConvert">
						{cryptoConvert.toUpperCase()}
					</span>
				</BoxInput>
			)}
		</Container>
	);
};

export default CryptoConvert;
