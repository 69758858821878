import Link from "next/link";
import { Container } from "./styles";

interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  width?: string;
  variant?: "primary" | "secondary" | "tertiary";
  href?: string;
  download?: string;
  self?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  width,
  variant = "primary",
  href,
  download,
  self,
}) => {
  if (href)
    return (
      <Link href={href}>
        <a target={self ? "_self" : "_blank"} download={download}>
          <Container width={width} disabled={disabled} className={variant}>
            {children}
            <span>Em breve</span>
          </Container>
        </a>
      </Link>
    );
  return (
    <Container disabled={disabled} width={width} className={variant}>
      {children}
      <span>Em breve</span>
    </Container>
  );
};

export default Button;
