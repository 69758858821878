import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
	color?: string;
	position?: string;
	width?: number;
}

export const Container = styled.div`
	background: ${theme("colors.white")};
	box-shadow: 0px 10px 25px rgba(26, 36, 137, 0.05);
	border-radius: 2px;
	padding: 55px;
	min-width: 605px;
	position: relative;

	h2 {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${theme("colors.light.gray.2")};
		margin-bottom: 16px;
	}
@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
    min-width: 100%;
		padding: 21px 18px;

		img {
			width: fit-content;
		}
	}

	.input {
		position: relative;
	}

	span {
		position: absolute;
		top: 20px;
		right: 20px;

		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: ${theme("colors.light.gray.2")};

		@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
			&.cryptoConvert {
				top: 85px;
			}
		}
	}
`;

export const BoxInput = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 10px;
	position: relative;

	> div {
		width: 100%;

		> input {
			padding-top: 0;
			background: rgba(229, 233, 238, 0.5);
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: ${theme("colors.light.gray.1")};
		}
	}

	select {
		padding-top: 0;
		padding-left: 53px;
	}

	.crypto {
		position: relative;
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		flex-direction: column;
	}
`;

export const BoxIcon = styled.div<Props>`
	width: ${(props) => props.width ?? 32}px;
	height: ${(props) => props.width ?? 32}px;
	border-radius: 50%;
	background-color: ${(props) => props.color && props.color};
	position: ${(props) => props.position ?? "absolute"};
	top: 12px;
	left: 11px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const BoxCrypto = styled.div`
	display: flex;
	align-items: center;
	gap: 29px;

	> div {
		width: 100%;
	}

	button,
	a {
		height: 56px;
		min-width: 174px;
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		flex-direction: column;
		gap: 10px;

		.cryptoConvert {
			top: 20px !important;
		}

		button,
		a {
			width: 100%;
		}
	}
`;

export const ContentTotal = styled.div`
	margin-top: 15px;

	div {
		margin-top: 0;
	}
`;

export const Footer = styled.div`
	margin-top: 30px;
	display: flex;
	gap: 13px;

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		flex-direction: column;
	}
`;

export const ContentIcon = styled.div`
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: rgba(229, 233, 238, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;

	i {
		color: ${theme("colors.primary.main")};
	}
`;

export const Text = styled.div`
	max-width: 300px;

	h3,
	p,
	a {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}

	h3 {
		color: ${theme("colors.light.gray.1")};
	}

	p {
		color: ${theme("colors.light.gray.2")};
	}

	a {
		text-decoration-line: underline;
		color: ${theme("colors.primary.main")};
	}
`;

export const WidgetCrypto = styled.div`
	padding: 17px 15px;
	background: ${theme("colors.white")};
	border: 1px solid ${theme("colors.light.gray.4")};
	box-shadow: 0px 10px 25px rgba(26, 36, 137, 0.05);
	border-radius: 2px;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	gap: 16px;

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		padding: 20px 15px;
	}
`;

export const InfosConvert = styled.div`
	h1 {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: ${theme("colors.light.gray.1")};
	}

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;

		img {
			margin-right: 5px;
		}

		&.up {
			font-weight: 500;
			color: ${theme("colors.success.main")};
		}

		&.down {
			font-weight: 500;
			color: ${theme("colors.error.main")};
		}
	}
`;
