import styled from "styled-components";
import { theme } from "styled-tools";

interface ButtonProps {
	width?: string;
}

export const Container = styled.button<ButtonProps>`
	background-color: ${theme("colors.primary.main")};
	border-radius: 2px;
	width: ${(props) => props.width ?? "202px"};
	border: none;
	height: 46px;
	cursor: pointer;
	position: relative;
  box-shadow: 0px 10px 25px 0px rgba(255, 70, 90, 0.20);
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${theme("colors.white")};
	transition: background-color 0.5s linear;

	:hover:enabled {
		background-color: ${theme("colors.primary.dark")};
	}

	&.secondary {
		background-color: ${theme("colors.light.gray.4")};
		color: ${theme("colors.primary.main")};
    box-shadow: none;

		:hover:enabled {
			background-color: ${theme("colors.primary.main")};
			color: ${theme("colors.white")};
		}
	}

	&.tertiary {
		background-color: ${theme("colors.crypto.bitcoin")};
	}

	:hover:disabled span {
		visibility: visible;
	}

	:disabled {
		cursor: default;
		background-color: ${theme("colors.light.gray.4")};
		color: ${theme("colors.light.gray.3")};
	}

	:enabled {
		span {
			display: none;
		}
	}

	span {
		visibility: hidden;
		background-color: ${theme("colors.primary.main")};
		color: ${theme("colors.white")};
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		box-shadow: 0px 10px 25px rgba(255, 70, 90, 0.2);
		border-radius: 2px;
		padding: 5px 0;
		width: 90px;

		position: absolute;
		top: -8px;
		right: -80px;
		z-index: 1;
	}
`;
