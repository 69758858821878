import { SEO, themes } from "@2ndmarket/components";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

interface Props {
  title: string;
  description: string;
}

const BankaiSEO: React.FC<Props> = ({ title, description }) => {
  const url = ProductUrls.BANKAI;

  const params = {
    appName: "Bank-ai",
    title: title,
    description: description,
    image: url + "img/banner.jpg",
    imageWidth: "1080",
    imageHeight: "1080",
    themeColor: themes.bankai.colors.primary.main,
    url: url,
  };

  return <SEO {...params}></SEO>;
};

export default BankaiSEO;
