import styled from "styled-components";
import { theme } from "styled-tools";

export const Cartola = styled.h2`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${theme("colors.primary.main")};
`;

export const Title = styled.h1`
	font-weight: 700;
	font-size: 42px;
	line-height: 50px;
	color: ${theme("colors.light.gray.1")};

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		font-size: 30px;
		line-height: 36px;
	}
`;

export const Paragraph = styled.p`
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: ${theme("colors.light.gray.2")};

	a {
		:hover {
			text-decoration: underline;
		}
	}

	&.paragraph-internal {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}
`;

export const SubTitle = styled.h3`
	font-size: 32px;
	font-weight: 600;
	line-height: 36px;
	margin-bottom: 20px;
`;
