import Link from "next/link";
import { useState } from "react";
import { useRouter } from "next/router";
import { Icon, themes } from "@2ndmarket/components";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import Button from "../Button";
import {
  Nav,
  Top,
  Left,
  Actions,
  MenuItem,
  Container,
  NavMobile,
  ButtonNav,
  ButtonMenu,
  ContentNav,
  MenuContent,
  HeaderMobile,
  ContentActions,
} from "./styles";

const Header: React.FC = () => {
  const router = useRouter();

  const [menuShow, setMenuShow] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const [idSubMenu, setIdSubMenu] = useState("");

  const menu = [
    {
      id: "sobre",
      name: "Sobre",
      href: "/sobre",
    },
    {
      id: "cripto",
      name: "Cripto",
      children: [
        {
          name: "Bitcoin",
          abv: "BTC",
          href: "/cripto/btc",
        },
      ],
    },
    {
      id: "card",
      name: "Bank.ai Card",
      href: "/bankai-card",
    },
    {
      id: "coin",
      name: "Bkai Coin",
      href: "/bkai-coin",
    },
    {
      id: "b2b",
      name: "B2B",
      children: [
        {
          name: "Para empresas",
          href: "/b2b/para-empresas",
        },
        {
          name: "API",
          href: "/b2b/api",
        },
      ],
    },
    {
      id: "otc",
      name: "OTC",
      href: "/otc",
    },
    {
      id: "blog",
      name: "Blog",
      target: "_blank",
      href: "https://blog.2ndmarket.com.br",
    },
    {
      id: "suporte",
      name: "Suporte",
      target: "_blank",
      href: "https://suporte.2ndmarket.com.br/pt-BR/",
    },
    // {
    // 	id: "contato",
    // 	name: "Contato",
    // 	href: "/",
    // },
  ];

  return (
    <Container>
      <Left>
        <Link href="/">
          <a>
            <img src="/bankai-logo.svg" alt="Logo Bank.ai" />
          </a>
        </Link>
        <Nav>
          <ul>
            {menu.map((item, key) =>
              item.children ? (
                <li
                  key={key}
                  className={router.asPath.match(item.id) && "active"}>
                  {item.name} <span>+</span>
                  <MenuContent>
                    {item.children.map((cripto: any, keyCripto: number) => (
                      <Link
                        key={keyCripto}
                        href={cripto.href != "" ? cripto.href : "#"}>
                        <MenuItem
                          className={cripto.href != "" ? "active" : "disabled"}>
                          <p>{cripto.name}</p>
                          <span>{cripto.abv}</span>
                        </MenuItem>
                      </Link>
                    ))}
                  </MenuContent>
                </li>
              ) : (
                <li key={key}>
                  <Link href={item.href}>
                    <a
                      target={item.target}
                      className={router.asPath.match(item.id) && "active"}>
                      {item.name}
                    </a>
                  </Link>
                </li>
              )
            )}
          </ul>
        </Nav>
      </Left>
      <Actions>
        <Link href={`${ProductUrls.AUTH_LOGIN}?redirect=bankai`} passHref>
          <a target="_blank" rel="noopener noreferrer">
            Login
          </a>
        </Link>
        <Button href={ProductUrls.AUTH_REGISTER} width="145px">
          Cadastre-se
        </Button>
      </Actions>

      <HeaderMobile>
        {menuShow && (
          <NavMobile>
            <Top>
              {showSubMenu ? (
                <>
                  <button onClick={() => setShowSubMenu(false)}>
                    <Icon name="arrow-alternative" size={20} rotate="180deg" />
                  </button>
                  <div />
                </>
              ) : (
                <>
                  <div />
                  <button onClick={() => setMenuShow(false)}>
                    <Icon name="close" size={20} />
                  </button>
                </>
              )}
            </Top>
            <ContentNav>
              {menu.map((item, key) => (
                <div key={key}>
                  {showSubMenu ? (
                    <>
                      {idSubMenu === item.id &&
                        item.children.map((subItem, keyItem) => (
                          <Link href={subItem.href} key={keyItem}>
                            <ButtonNav>
                              <p>{subItem.name}</p>
                              <span>{subItem.abv}</span>
                            </ButtonNav>
                          </Link>
                        ))}
                    </>
                  ) : (
                    <>
                      {item.href ? (
                        <Link href={item.href}>
                          <ButtonNav key={key}>
                            <p>{item.name}</p>
                          </ButtonNav>
                        </Link>
                      ) : (
                        <ButtonNav
                          key={key}
                          onClick={() => {
                            setIdSubMenu(item.id), setShowSubMenu(true);
                          }}>
                          <p>{item.name}</p>
                          <Icon
                            name="arrow"
                            rotate="-90deg"
                            color={themes.bankai.colors.light.gray[3]}
                          />
                        </ButtonNav>
                      )}
                    </>
                  )}
                </div>
              ))}
            </ContentNav>
            <ContentActions>
              <Button
                variant="secondary"
                href={`${ProductUrls.AUTH_LOGIN}?redirect=bankai`}>
                Login
              </Button>
              <Button href={ProductUrls.AUTH_REGISTER}>Cadastre-se</Button>
            </ContentActions>
          </NavMobile>
        )}
        <ButtonMenu onClick={() => setMenuShow(true)}>
          <Icon
            name="menu"
            size={25}
            color={themes.bankai.colors.light.gray[2]}
          />
        </ButtonMenu>
      </HeaderMobile>
    </Container>
  );
};

export default Header;
