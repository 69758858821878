export enum SecondMarketSocials {
  FACEBOOK = "https://www.facebook.com/2ndmkt",
  INSTAGRAM = "https://www.instagram.com/2nd__market/",
  LINKEDIN = "https://www.linkedin.com/company/2nd-market/",
}

export enum BankaiSocials {
  FACEBOOK = "https://www.facebook.com/bankai.io",
  INSTAGRAM = "https://www.instagram.com/bank__ai/",
  LINKEDIN = "https://www.linkedin.com/company/bank-ai/",
}

export enum BraexSocials {
  FACEBOOK = "https://www.facebook.com/Braex-103497092096844",
  INSTAGRAM = "https://www.instagram.com/bra__ex/",
  LINKEDIN = "https://www.linkedin.com/company/bra-ex/",
}

export enum CustonSocials {
  FACEBOOK = "https://www.facebook.com/people/CustON/100086172694343/",
  INSTAGRAM = "https://www.instagram.com/cust_on.io/",
  LINKEDIN = "https://www.linkedin.com/company/cust-on",
}

export enum TokenaimSocials {
  FACEBOOK = "https://www.facebook.com/profile.php?id=100075833889805",
  INSTAGRAM = "https://www.instagram.com/token__aim/",
  LINKEDIN = "https://www.linkedin.com/company/76163825/",
}
