import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 19.5px 4vw;
	position: fixed;
	width: 100%;
	background-color: ${theme("colors.background")};
	z-index: 9;
	top: 0;

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		padding: 19.5px 4vw;
	}
`;

export const Left = styled.div`
	gap: 6vw;
	display: flex;
	align-items: center;
`;

export const Nav = styled.nav`
	ul {
		display: flex;
		list-style-type: none;
		gap: 28px;
	}

	li,
	a {
		cursor: pointer;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: ${theme("colors.light.gray.1")};
		position: relative;
		display: inline-block;
		background-clip: padding-box;

		&.active {
			color: ${theme("colors.primary.main")};
		}

		> span {
			color: ${theme("colors.primary.main")};
		}

		:hover {
			color: ${theme("colors.primary.main")};
		}

		:hover > div {
			display: block;
		}
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		display: none;
	}
`;

export const Actions = styled.div`
	display: flex;
	gap: 37px;
	align-items: center;

	a {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: ${theme("colors.light.gray.1")};
		text-decoration: none;

		:hover {
			color: ${theme("colors.primary.main")};
		}
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		display: none;
	}
`;

export const MenuContent = styled.div`
	background: ${theme("colors.white")};
	box-shadow: 0px 10px 25px rgba(26, 36, 137, 0.05);
	border-radius: 2px;
	max-height: 505px;
	padding: 2px 30px;
	width: 307px;
	display: none;
	position: absolute;
	z-index: 1;
	cursor: default;
	overflow-y: scroll;

	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-thumb {
		background: ${theme("colors.light.gray.4")};
		border-radius: 60px;
	}
`;

export const MenuItem = styled.a`
	padding: 16px 0;
	align-items: center;
	display: flex !important;
	justify-content: space-between;
	border-bottom: 1px solid ${theme("colors.light.gray.4")};

	:last-child {
		border: none;
	}

	&.disabled {
		cursor: not-allowed;

		:hover {
			p {
				color: ${theme("colors.light.gray.2")} !important;
			}
			span {
				color: ${theme("colors.light.gray.3")} !important;
			}
		}
	}

	p {
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;
		color: ${theme("colors.light.gray.2")};
	}

	span {
		font-size: 12px;
		font-weight: 500;
		line-height: 15px;
		color: ${theme("colors.light.gray.3")} !important;
	}

	:hover {
		span,
		p {
			color: ${theme("colors.primary.main")} !important;
		}
	}
`;

export const HeaderMobile = styled.div`
	@media only screen and (min-width: ${theme("breakpoints.extralarge")}) {
		display: none;
	}
`;

export const NavMobile = styled.div`
	z-index: 9;
	background-color: ${theme("colors.background")};
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 27px 4vw 20px;

	@media only screen and (min-width: ${theme("breakpoints.extralarge")}) {
		display: none;
	}
`;

export const ButtonMenu = styled.button`
	background-color: transparent;

	@media only screen and (min-width: ${theme("breakpoints.extralarge")}) {
		display: none;
	}
`;

export const Top = styled.div`
	display: flex;
	justify-content: space-between;

	i {
		color: ${theme("colors.light.gray.2")};
	}

	button {
		background-color: transparent;
	}
`;

export const ContentNav = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 18px;
`;

export const ButtonNav = styled.button`
	background-color: transparent;
	padding: 16px 0;
	text-align: start;
	border-bottom: 1px solid ${theme("colors.light.gray.4")};
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: ${theme("colors.light.gray.1")};
	}

	span {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: ${theme("colors.light.gray.3")};
	}
`;

export const ContentActions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: absolute;
	width: calc(100% - 4vw);
	bottom: 20px;
	padding-right: 4vw;

	button,
	a {
		width: 100%;
	}
`;
