import { ReactElement } from "react";
import { Container, BoxIcon, Text } from "./styles";

interface CardProps {
  title: string;
  text: string;
  icon: any;
  bg?: string;
}

const CardStep: React.FC<CardProps> = ({ title, text, icon, bg }) => {
  return (
    <Container>
      <BoxIcon bg={bg}>{icon}</BoxIcon>
      <Text>
        <h2>{title}</h2>
        <p>{text}</p>
      </Text>
    </Container>
  );
};

export default CardStep;
