import styled from "styled-components";
import { theme } from "styled-tools";

export const Container = styled.a`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	cursor: pointer;
	transition: opacity 0.25s, transform 0.5s;

	img {
		width: 100%;
		height: 235px;
		object-fit: cover;
		object-position: center;
		border-radius: 3px;
	}

	:hover {
		opacity: 0.8;
		transform: translateY(-2px);
	}

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		gap: 13px;
	}
`;

export const Text = styled.div`
	h3 {
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: ${theme("colors.light.gray.1")};
		margin: 12px 0;

		@media only screen and (max-width: ${theme(
				"breakpoints.extralarge"
			)}) {
			margin: 7px 0;
		}
	}

	span {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: ${theme("colors.light.gray.3")};
	}
`;
