import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
	marginX?: number;
	justifyContent?:
		| "center"
		| "end"
		| "flex-start"
		| "flex-end"
		| "space-between";
	reverse?: boolean;
	column?: boolean;
	maxWidth?: number;
	gap?: number;
	gapMobile?: number;
	wrap?: string;
	mobileReverse?: boolean;
	marginXMobile?: number;
	mtMobile?: number;
}

export const Container = styled.div<Props>`
	padding: 75px ${(props) => props.marginX ?? "6"}vw;
	gap: ${(props) => props.gap ?? "7"}vw;
	display: flex;
	flex-direction: ${(props) => props.reverse && "row-reverse"};
	align-items: center;
	justify-content: space-between;
	max-width: ${(props) => props.maxWidth && props.maxWidth}vw;
	position: relative;

	img {
		border-radius: 3px;
	}

	.decoration {
		position: absolute;

		&.one {
			bottom: 107px;
			left: 598px;
		}

		@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
			display: none;
		}
	}

	.page-bankai-coin {
		display: flex;
		align-items: center;
		flex-direction: column;

		.bkai-coin {
			gap: 210px;
			padding: 1vw;
			display: flex;
			align-items: center;
			background-color: ${theme("colors.white")};

			position: absolute;
			bottom: 100px;

			.bkai-coin__content {
				gap: 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			p {
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
			}

			button {
				width: 115px;
			}
		}

		@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-content: flex-end;

			.bkai-coin {
				gap: 40px;
				top: 75vw;
				bottom: unset;

				width: 80%;
				padding: 3vw;
				display: flex;
				flex-direction: row;

				.bkai-coin__content {
					gap: 10px;
					width: 45%;
					justify-content: space-around;
				}
			}

		}
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		flex-direction: ${(props) =>
			props.mobileReverse ? "column-reverse" : "column"};
		padding: 30px 4vw;
		gap: 20px;
		max-width: 100%;
		margin-top: ${(props) => props.mtMobile ?? "0"}px;

		img {
			width: 100%;
		}
	}
`;

export const Text = styled.article<Props>`
	h1 {
		margin-bottom: 14px;
	}

	h2 {
		margin-bottom: 6px;
	}

	#crypto {
		font-weight: 700;
		font-size: 42px;
		line-height: 50px;
		color: ${theme("colors.primary.main")};
		min-height: 50px;

		@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
			font-size: 30px;
			line-height: 36px;
		}
	}

	ul {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		list-style: inside;
		color: ${theme("colors.light.gray.2")};
	}

	.page-sobre {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}

	&.list-block {
		h2,
		p {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}
	}

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		h3 {
			font-size: 24px;
			font-weight: 600;
			line-height: 30px;
		}
	}
`;

export const Additional = styled.article<Props>`
	display: flex;
	flex-direction: ${(props) => props.column && "column"};
	justify-content: ${(props) =>
		props.justifyContent && props.justifyContent};
	margin: 25px 0;
	gap: ${(props) => props.gap ?? "18"}px;

	@media only screen and (max-width: ${theme("breakpoints.extralarge")}) {
		flex-wrap: ${(props) => props.wrap ?? ""};
		gap: ${(props) => props.gapMobile ?? "18"}px;
		margin: 25px ${(props) => props.marginXMobile ?? 0}vw;
	}
`;

export const ContentIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${theme("colors.primary.main")};
	min-width: 40px;
	height: 40px;
	border-radius: 50%;

	i {
		left: 0 !important;
		color: ${theme("colors.white")};
	}
`;
