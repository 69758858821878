import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
	bg?: string;
}

export const Container = styled.div`
	padding: 31px 2vw;
	border: 1px solid ${theme("colors.light.gray.4")};
	border-radius: 2px;
	width: 100%;
	display: flex;
	height: 100%;
	flex-direction: column;
	gap: 19px;

	@media only screen and (max-width: ${theme(
			"breakpoints.extralarge"
		)}) {
		padding: 21px 16px;
		max-width: 100%;
	}
`;

export const BoxIcon = styled.div<Props>`
	width: 58px;
	height: 58px;
	background: ${(props) =>
		props.bg ?? "rgba(229, 233, 238, 0.6)"};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	> i {
		color: ${theme("colors.primary.main")};
	}
`;

export const Text = styled.div`
	display: flex;
	flex-direction: column;
	gap: 9px;

	h2 {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: ${theme("colors.light.gray.1")};
	}

	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${theme("colors.light.gray.2")};
	}
`;
